import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Notes App Template | Notes App Design | Uizard"
    canonical="/templates/mobile-app-templates/notes-mobile-app/"
    category="Tablet Templates"
    url="/templates/tablet-templates/notes-tablet-app/"
    metaDescription="Design your own sticky notes app for tablet with our premade design template. Streamline your design flow with Uizard Pro today."
    description="
    h2:A notes app design template for tablet devices
    <br/>
    Our brand-new template is here to streamline the design flow of your sticky notes app project. Our <a:https://uizard.io/templates/>UI design templates</a> come fully equipped with everything you could possibly need for your own app design project. Simply sign up to Uizard Pro and get to work.
    <br/>
    h3:Create a tablet app mockup collaboratively with Uizard
    <br/>
    Prototyping and <a:https://uizard.io/wireframing/>UI wireframing software</a> doesn't have to be difficult to use. Making your own notes app design for tablet has never been easier than it is with Uizard's sticky notes template.
    <br/>
    h3:Customization our premade templates to suit your own branding or vision
    <br/>
    Uizard templates are designed for easy customization and collaborative working. Sign up to Uizard, invite your team members, leave feedback, add and remove screens, and complete your design project alongside your colleagues in next to on time.
    "
    pages={[
      "A colorful app log-in page",
      "A sticky notes landing page for users to store all their thoughts and tasks",
      "'Add a note' user flow with all the must-have screens",
      "Voice notes mockup and note category lists to demonstrate the versatility of your app design",
    ]}
    projectCode="nYPLwRwlqMIMR8jxGb6x"
    img1={data.image1.childImageSharp}
    img1alt="note taking tablet app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="note taking tablet app design template home screen"
    img3={data.image3.childImageSharp}
    img3alt="note taking tablet app design template list screen"
    img4={data.image4.childImageSharp}
    img4alt="note taking tablet app design template voice record screen"
    img5={data.image5.childImageSharp}
    img5alt="note taking tablet app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/note-taking-tablet-app/notes-tab-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/note-taking-tablet-app/notes-tab-app-home.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/note-taking-tablet-app/notes-tab-app-list.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/note-taking-tablet-app/notes-tab-app-voice.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/note-taking-tablet-app/notes-tab-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
